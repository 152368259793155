// core
import { mapGetters } from 'vuex';
import Settings from '@/config/Settings';

// components
import Layout from '@/Views/_Layout/index.vue';
import HelpMenu from '@/Views/_Components/HelpMenu/index.vue';
import InterseptedImage from '@/Views/_Components/Helpers/InterseptedImage/index.vue';

// helpers
import { slugify } from '@/Helpers/Misc';

// icons
import { XIcon, SearchIcon } from 'vue-feather-icons';

export default {
	name: 'Search',

	data() {
		return {
			form: {
				term: ''
			},
			page: 1
		};
	},

	components: {
		// components
		'oston-layout': Layout,
		'oston-help-menu': HelpMenu,
		'oston-intersepted-image': InterseptedImage,

		// icons
		'feather-icon-close': XIcon,
		'feather-icon-search': SearchIcon
	},

	mounted() {
		Settings.title('Pesquisa');
		_.controller('search').reset();
	},

	methods: {
		slugify,

		search: function(e) {
			_.controller('search').reset();

			if (e.target.value.length > 0) {
        _.controller('loading').set(true);
				this.debounce(e.target.value);
			}
		},

		debounce: _.debounce((value) => {
			_.controller('search').get(value);
		}, 1000),

		more: function() {
			this.page = this.page + 1;
			_.controller('search').get(this.term, this.page, () => {
				setTimeout(() => {
					window.scrollTo({
						left: 0,
						top: document.body.scrollHeight || document.documentElement.scrollHeight
					});
				}, 25);
			});
		},

		reset: function() {
			this.form.term = '';
			this.page = 1;
			_.controller('search').reset();
		}
	},

	computed: mapGetters({
		result: 'SearchModel/content',
		term: 'SearchModel/term',
		lastPage: 'SearchModel/lastPage',
		loading: 'LoadingModel/loading'
	})
};
